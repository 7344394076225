.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url('../../images/RAISE_Hero_1920x1280.svg') center center no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.main-content {
    text-align: center;
}

img {
    width: 200px;
    /* Adjust as needed */
    height: 200px;
    /* Adjust as needed */
    margin-bottom: 20px;
}

.footer {
    text-align: center;
    margin-top: 20px;
}