.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #3367d6;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
}


.upload-input {
    display: none;
}

.upload-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #3367d6;
    color: #fff;
    border-radius: 4px;
    outline: none;
}

.upload-submit {
    background-color: #4caf50;
}

.upload-button:hover {
    background-color: #2555a6;
}

.upload-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}