.navbar-section {
    display: flex;
    background: black;
    color: #fff;
}
.navbar-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
}

.navbar-items {
    display: flex;
}

.navbar-logo .uno-logo {
    width: 50px;
    height: 50px;
    margin: 5px 0 0 0;
}

.navbar-items .navbar-item {
    padding: 20px;
    border-right: 1px solid #555;
}

.navbar-items .navbar-item a {
    text-decoration: none;
}

svg {
    fill: #ddd;
}

.navbar-item-logout {
    margin-top: 15px;
}

.navbar-logout {
    cursor: pointer
}

button svg {
    fill: #c9c9c9;
}

