@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.dashboard {
    display: grid;
    grid-template-columns: 23vw 1fr;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 100px;
}

.db-sidebar {
    padding: 20px 10px;
    display: flex;
    width: 23vw;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid #555;
    font-size: 0.95em;
    font-weight: 700;
    overflow: scroll;
    scroll-behavior: smooth;
}

.db-sidebar .db-link {
    transition: 200ms ease-in-out;
}
.db-sidebar .db-link a {
    transition: 200ms ease-in-out;
}

.db-sidebar .db-link:hover{
    background-color: #D71920;
    color: #ffffff;
}

.db-sidebar .db-link:visited{
    background-color: #D71920;
    color: #ffffff;
}

.db-sidebar .db-link a:hover{
    background-color: #D71920;
    color: #ffffff;
}

.db-unit p {
    color: #D71920;
    text-align: start;
}

.db-items {
    font-size: 0.85em;
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-left: 10px;
}

.db-item svg {
    margin-left: 5px;
}

.db-item {
    padding: 10px;
    
}

.db-content {
    width: 100%;
}

.dropdown-container {
    display: none;
    list-style-type: decimal;
}

.db-skeleton {
    padding: 10%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.db-sidebar::-webkit-scrollbar-thumb {
    background: black;
}

.dashboard-img {
    width: 70%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
}

.db-nested-content {
    list-style-type: disc;
    text-decoration: underline;
}

.mod-1 {
    margin-left: -20px;
}

.db-link {
    display: flex;
    gap: 10px;
}
