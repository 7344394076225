.parsons-container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.code-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.code-block {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: grab;
}

.code-block:hover {
    background-color: #f0f0f0;
}